<template>
  <div class="worker-applications my-2">
    <div v-if="isLoading">
      <v-skeleton-loader
        data-test="loader"
        v-for="i in 5"
        :key="i"
        type="list-item-avatar"
      />
    </div>
    <v-list-item
      v-else
      v-for="application in items"
      :key="application.id"
      @click="() => handleView(application)"
    >
      <WorkerApplicationItem :application="application" />
    </v-list-item>
    <v-pagination
      :total-visible="page.pageSize"
      v-if="showPagination"
      v-model="page.currentPage"
      @input="loadApplications"
      :length="page.lastPage"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { get } from "lodash";
import WorkerApplicationItem from "@/views/workers/WorkerDetails/components/WorkerApplicationItem";
import { paginationMixin } from "@/mixins/pagination.mixin";
import { GET_WORKER_APPLICATIONS } from "@/store/modules/workers/action-types";

export default {
  components: {
    WorkerApplicationItem
  },
  mixins: [paginationMixin],
  created() {
    this.page = {
      pageSize: 10,
      currentPage: 1,
      lastPage: 1
    };
    this.loadApplications();
  },
  computed: {
    ...mapState("workers", {
      applications: state => state.applications,
      isLoading: state => state.isFetchingApplications
    }),
    items() {
      return this.applications.map(item => ({
        status: item.status,
        organisation: item.organisation && item.organisation.name,
        booking: {
          id: this.getBookingItem(item, "id"),
          title: this.getBookingItem(item, "title")
        }
      }));
    },
    currentWorkerId() {
      return this.$route.params.id;
    }
  },
  methods: {
    ...mapActions("workers", {
      fetchApplications: GET_WORKER_APPLICATIONS
    }),
    async loadApplications() {
      const data = await this.fetchApplications({
        "filter[user_id]": this.currentWorkerId,
        ...this.requestPageParams,
        sort: "-createdAt"
      });
      if (data) {
        const { meta } = data;
        this.updatePageDetails(meta);
      }
    },
    getBookingItem(application, key) {
      return get(application.booking, key) || "None";
    },
    handleView({ booking }) {
      this.$router.push({
        name: "bookingDetails",
        params: { id: booking.id }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.worker-applications {
  margin: 0 -15px;
}
</style>
